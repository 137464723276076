<template>
  <v-row class="invoices">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between align-baseline">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Invoices</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by invoices number"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Looks like there are not invoices according to your request</div>
        </v-card>
        <v-card
          flat
          height="70vh"
          min-height="260"
          v-else-if="block || !data.result.length"
          class="pa-3 d-flex align-center justify-center flex-column"
        >
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h6 font-weight-bold mt-8">Looks like there are no invoices for your account.</div>
          <!-- <div class="text-center opasity--text font-weight-regular mt-2">Please, contact support to resolve this issue.</div> -->
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="name"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="p-table"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:header.status>
              Status
              <v-btn @click="toggleOrder" icon class="btn-sort" :class="sortDesc ? 'input white--text' : ''">
                <v-icon>{{ sortDesc ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52">
                  <td>{{ item.number }}</td>
                  <td>{{ new Date(item.issue).toLocaleString() }}</td>
                  <td class="d-sm-table-cell d-none clip">{{ item.description }}</td>
                  <td>{{ item.amount }}</td>
                  <td>
                    <div class="status" :class="getColorClass(item.status.name)">{{ item.status.title }}</div>
                  </td>
                  <td class="text-right">
                    <v-btn
                      class="rounded"
                      color="input"
                      text
                      icon
                      tile
                      depressed
                      @click="saveFile(item.id, 'pdf')"
                      :loading="loader"
                      :disabled="loader"
                    >
                      PDF
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      loader: false,
      sortBy: 'status',
      sortDesc: false,
      load: true,
      block: true,
      nothing: false,
      headers: [
        { text: 'No.', value: 'number', sortable: false },
        { text: 'Date of issue', value: 'issue', sortable: false },
        { text: 'Description', value: 'description', class: 'd-sm-table-cell d-none', sortable: false },
        { text: 'Amount, $', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Download', align: 'end', value: 'file', sortable: false },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    getColorClass(block) {
      if (block == 'paid') {
        return 'success';
      } else if (block == 'partly_paid') {
        return 'warning';
      } else if (block == 'unpaid') {
        return 'error';
      }
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async saveFile(id, type) {
      this.loader = true;
      this.$store
        .dispatch('getInvoicesFile', {
          id: id,
          type: type,
        })
        .then(() => {
          const blob = new Blob([this.invoice], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'invoice.pdf';
          link.click();
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async getData() {
      await this.$store
        .dispatch('getInvoicesList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setInvoicesList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setInvoicesList', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.invoicesList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    invoice() {
      return this.$store.getters.invoicesFile;
    },
  },
  destroyed() {
    this.$store.dispatch('setInvoicesList', {});
  },
};
</script>

<style lang="scss">
.invoices {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  .status {
    color: white;
    line-height: 24px;
    padding: 0 16px;
    width: max-content;
    border-radius: 24px;
    text-transform: capitalize;
  }
}
</style>
